<template id="tmpOrderDetails">
  <div id="divOrderDetails" class="container vld-parent">
    <loading
      id="ldingOrderDetails"
      :active.sync="isLoading"
      :is-full-page="false"
      color="#114C8E"
    ></loading>
    <b-row id="rowOrdDetailOrderNum">
      <b-card id="crdOrdDetailOrderNum" class="w-100">
        <div id="divOrdDetailOrderNum" ref="content">
          <b-card-title id="crdTitleOrdDetailsOrderNum">
            <b-row id="rowOrdDetailID">
              <b-col id="colOrderDetailOrderNumID" col-6>Order # {{ this.$route.params.id }}</b-col>
              <b-col id="colOrderDetailOrderNumPrintBtn" col-6>
                <PrintButton
                  id="btnOrderDetaiilsPrint"
                  v-if="orderDetails"
                  :orderNumber="orderDetails.orderNumber"
                ></PrintButton>
              </b-col>
            </b-row>
          </b-card-title>

          <b-card v-if="wasLoadingError">
            <b-alert variant="danger" show="true">
              <h4 class="alert-heading my-2">{{ this.errorMessage.title }}</h4>

              <p>{{ this.errorMessage.detail }}</p>
            </b-alert>
          </b-card>

          <div v-if="orderDetails">
            <b-row class="mb-4">
              <b-col lg="4" class="mb-2 mb-lg-0">
                <Details :orderDetails="orderDetails"></Details>
              </b-col>

              <b-col lg="4" class="mb-2 mb-lg-0">
                <ReferenceNumbers
                  :orderDetails="orderDetails"
                ></ReferenceNumbers>
              </b-col>

              <b-col lg="4" class="mb-2 mb-lg-0">
                <Assets :orderDetails="orderDetails"></Assets>
              </b-col>
            </b-row>

            <b-row v-if="orderDetails.instructions">
              <b-col>
                <b-card title="Remarks">
                  {{ orderDetails.instructions }}
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <Stops :orderDetails="orderDetails"></Stops>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="vld-parent" v-if="orderDetails && showImageSection">
          <loading
            :active.sync="isLoadingImages"
            :is-full-page="false"
            color="#114C8E"
          ></loading>
          <b-row>
            <b-col>
              <b-card title="Images/Documents">
                <b-alert variant="danger" v-model="wasImageError">
                  <h4 class="alert-heading">Error!</h4>
                  <p>There was an error loading the order images/documents.</p>
                  <b-button id="btnOrderDetailLoadImages" variant="danger" size="sm" @click="loadImages"
                    >Click here to try again</b-button
                  >
                </b-alert>

                <div id="divOrderDetailImageDownload" v-if="!isLoadingImages && !wasImageError">
                  <b-row id="rowOrderDetailImageDownload" class="mb-2">
                    <b-col id="colOrderDetailImageDownload">
                      <b-button
                        id="btnOrderDetailSelectAllImages"
                        size="sm"
                        variant="success"
                        @click="onSelectAllImagesButtonClick()"
                      >
                        <i id="itlOrderDetailToggleAllImages" class="fa fa-check"></i> Toggle All Images
                      </b-button>

                      <div class="clearfix d-block d-sm-none">
                        <br />
                      </div>

                      <b-button
                        id="btnOrderDetailDownloadAllImanges"
                        size="sm"
                        variant="danger"
                        @click="onDownloadAllImagesButtonClick()"
                        :disabled="!enableDownload || showDownloadAllLoader"
                        class="ml-sm-2"
                      >
                        <i id="itlOrderDetailDownloadAllImages" class="fa fa-download mr-2"></i>
                        Download Selected Images
                        <span id="spnOrderDetialDownAllLoader"
                          v-if="showDownloadAllLoader"
                          class="spinner-border spinner-border-sm ml-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-table id="tblOrderDetailImageDetail"
                               :items="imageDetails"
                               :fields="imageFields"
                               show-empty
                               :small="true">
                        <template v-slot:cell(select)="row">
                          <b-form-checkbox
                            v-if="row.item.allowedToView || row.item.available"
                            v-model="row.item.isSelected"
                            :disabled="!row.item.allowedToView || !row.item.available"
                            @change="onImageSelected($event, row.item)"></b-form-checkbox>
                        </template>
                        <template v-slot:cell(image)="row">
                          <div v-if="row.item.allowedToView || row.item.available">
                          {{ row.item.docTypeName }}
                          <span class="text-danger">
                            {{
                            row.item.message
                              ? "(" + row.item.message.trim() + ")"
                              : ""
                            }}
                          </span>
                          </div>

                          <div class="d-lg-none">
                            <b-button size="sm"
                                      @click="onImageViewButtonClick(row.item)"
                                      :disabled="row.item.showViewLoader"
                                      variant="info"
                                      v-if="
                                row.item.allowedToView && row.item.available
                              ">
                              <i class="fa fa-eye mr-2"></i>
                              View
                              <span v-if="row.item.showViewLoader"
                                    class="spinner-border spinner-border-sm ml-1"
                                    role="status"
                                    aria-hidden="true"></span>
                            </b-button>

                            <b-button size="sm"
                                      class="m-2"
                                      @click="onImageDownloadButtonClick(row.item)"
                                      :disabled="row.item.showDownloadLoader"
                                      variant="danger"
                                      v-if="
                                row.item.allowedToView && row.item.available
                              ">
                              <i class="fa fa-download mr-2"></i> Download
                              <span v-if="row.item.showDownloadLoader"
                                    class="spinner-border spinner-border-sm ml-1"
                                    role="status"
                                    aria-hidden="true"></span>
                            </b-button>
                          </div>
                        </template>

                        <template v-slot:cell(actions)="row"
                                  class="d-none d-sm-table-cell">
                          <b-button size="sm"
                                    @click="onImageViewButtonClick(row.item)"
                                    :disabled="row.item.showViewLoader"
                                    variant="info"
                                    v-if="row.item.allowedToView && row.item.available">
                            <i class="fa fa-eye mr-2"></i>
                            View
                            <span v-if="row.item.showViewLoader"
                                  class="spinner-border spinner-border-sm ml-1"
                                  role="status"
                                  aria-hidden="true"></span>
                          </b-button>

                          <b-button size="sm"
                                    class="ml-sm-2"
                                    @click="onImageDownloadButtonClick(row.item)"
                                    :disabled="row.item.showDownloadLoader"
                                    variant="danger"
                                    v-if="row.item.allowedToView && row.item.available">
                            <i class="fa fa-download mr-2"></i> Download
                            <span v-if="row.item.showDownloadLoader"
                                  class="spinner-border spinner-border-sm ml-1"
                                  role="status"
                                  aria-hidden="true"></span>
                          </b-button>
                        </template>
                        <template v-slot:empty="scope" id="tmplTextNoImagesAvailable">
                          <h6 class="text-center" id="h5TextOne">There are no images available to view. For assistance contact your administrator.</h6>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <div class="vld-parent" v-if="orderDetails && showOrderMapsSection">
          <loading
            :active.sync="isLoadingMap"
            :is-full-page="false"
            color="#114C8E"
          ></loading>

          <b-row>
            <b-col>
              <b-card title="Map">
                <b-alert variant="danger" v-model="wasMapError">
                  <h4 class="alert-heading">Error!</h4>
                  <p>There was an error loading the order map.</p>
                  <b-button variant="danger" size="sm" @click="loadTheMap"
                    >Click here to try again</b-button
                  >
                </b-alert>

                <HereMap
                  apiKey="ZDGwdcxJZDMLvL8752hwfICLfMc8RrVs2n210tnSumU"
                  lat="37.7397"
                  lng="-121.4252"
                  width="100%"
                  height="835px"
                  v-if="orderLocationData"
                  :mapJson="orderLocationData"
                  :orderStatus="orderDetails.status"
                  :division="this.division"
                />
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-row>
    <div id="printVersion" v-if="orderDetails">
      <div ref="content">
        <table>
            <tr>
              <td class="col-print-1">
                <Details :orderDetails="orderDetails"></Details>
              </td>

              <td class="col-print-1">
                <ReferenceNumbers
                  :orderDetails="orderDetails"
                ></ReferenceNumbers>
              </td>

              <td class="col-print-1">
                <Assets :orderDetails="orderDetails"></Assets>
              </td>
            </tr>

            <tr>
              <td>
                <Stops :orderDetails="orderDetails"></Stops>
              </td>
            </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { canViewImages, canViewOrderMaps } from "@/shared/utils/permissions";
import { hereMapsApiKey } from "@/shared/constants";
import { mapState, mapGetters } from "vuex";
import mixin from "@/shared/mixin";

import _ from "lodash";
import { convertCanonicalToSomethingNotStupid } from "@/shared/utils/order-canonical-helper";

import HereMap from "@/components/orders/HereMap.vue";
import Details from "@/components/order-details/Details.vue";
import ReferenceNumbers from "@/components/order-details/Reference-Numbers.vue";
import Assets from "@/components/order-details/Assets.vue";
import Stops from "@/components/order-details/Stops.vue";
import PrintButton from "@/components/order-details/Print-Button.vue";

import { handleRequestErrorNew } from "@/shared/utils/response-error-handler";
import { enableImaging } from "@/shared/feature-flags";

  export default {
  Name: "OrderDetails.vue",
  components: {
    Loading,
    HereMap,
    Details,
    ReferenceNumbers,
    Assets,
    Stops,
    PrintButton
  },
  mixins: [mixin],
  data() {
    return {
      isLoading: false,
      wasLoadingError: false,
      errorMessage: "",
      isLoadingImages: false,
      wasImageError: false,
      isLoadingMap: false,
      wasMapError: false,
      showDownloadAllLoader: false,
      hereMapsKey: hereMapsApiKey,
      selectedImages: [],
      freightFields: [
        "supplier",
        "pincode",
        "accountOf",
        "commodity",
        "quantityNet",
        "quantityGross"
      ],
      spgFreightFields: [
        "supplier",
        "commodity",
        "quantityNet",
        "quantityGross"
      ],
      imageFields: [
        "select",
        "image",
        {
          key: "actions",
          thClass: "d-none  d-lg-table-cell",
          tdClass: "d-none  d-lg-table-cell"
        }
      ],
      orderLocationData: null,
      showSiteDetail: false,
      orderDetails: null,
      termTruck: null,
      company: null,
      desk: null,
      orderBillTo: null,
      orderLastDropStop: null,
      enableImaging
    };
  },
  async created() {
    this.isLoading = true;
    this.wasLoadingError = false;

    //for backwards compat. Division wasn't always required.
    await this.$store
      .dispatch("order/terminal", {
        orderNumber: this.$route.params.id,
        division: this.division,
        preferHeader: ""
      })
      .then(details => {
       // console.log("ORDER Terminal:", details);
        this.company = details.operatingCompany;
        this.desk = details.dispatchDesk;
        this.terminal = details.truckTerminal;
      });

    await this.$store
      .dispatch("order/getOrderDetails", {
        orderNumber: this.$route.params.id,
        division: this.division,
        preferHeader: ""
      })
      .then(details => {
        this.orderDetails = convertCanonicalToSomethingNotStupid(details);
        this.loadImages();
        this.loadTheMap();
        this.orderDetails.termCompany = this.company;
        this.orderDetails.desk = this.desk;
        this.orderDetails.termTruck = this.terminal; //termTruck;
      })
      .catch(e => {
        this.wasLoadingError = true;
        this.errorMessage = handleRequestErrorNew(e);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    loadImages() {
      if (this.showImageSection) {
        this.isLoadingImages = true;
        this.wasImageError = false;

        this.$store
          .dispatch("orderImage/getAllOrderImageData", {
            orderNumber: this.orderDetails.orderNumber,
            billTo: this.orderDetails.billTo.id,
            division: this.division,
            jwtToken: this.$store.state.user.jwtToken
          })
          .then(() => {})
          
          .catch(e => {
            this.wasImageError = true;
          })
          .finally(() => {
            this.isLoadingImages = false;
          });
      }
    },
    loadTheMap() {
      if (this.showOrderMapsSection) {
        this.isLoadingMap = true;
        this.wasMapError = false;

        this.$store
          .dispatch("location/getOrderLocationData", {
            orderNumber: this.$route.params.id,
            division: this.division,
            jwtToken: this.$store.state.user.jwtToken
          })
          .then(locations => {
            this.orderLocationData = locations;
          })
          .catch(() => {
            this.wasMapError = true;
          })
          .finally(() => {
            this.isLoadingMap = false;
          });
      }
    },

    utcDateToLocal(utcDate) {
        // .format("MM/DD/YYYY hh:mm a")
      return moment
        .utc(utcDate)
        .local()      
        .format("MM/DD/YYYY")
        .toString();
    },
    onImageSelected(e, image) {
      var data = {
        val: e,
        type: image.docType
      };

      this.$store.dispatch("orderImage/setDocTypeSelection", data);
    },
    async onImageViewButtonClick(image) {
      image.showViewLoader = true;

      try {
        var responseObject = await this.$store.dispatch(
          "orderImage/getOrderImage",
          {
            orderNumber: this.orderDetails.orderNumber,
            billTo: this.orderDetails.billTo.id,
            jwtToken: this.$store.state.user.jwtToken,
            docType: image.docType,
            division: this.division
          }
        );

        var blob = this.convertByteArrayToPdf(
          responseObject.bytes,
          responseObject.contentType
        );
        var fileURL = URL.createObjectURL(blob);

        window.open(fileURL, responseObject.fileName);
      } catch (e) {
        this.$snotify.error(
          "There was an error retrieving image data.",
          "Error"
        );
      } finally {
        image.showViewLoader = false;
      }
    },
    async onImageDownloadButtonClick(image) {
      image.showDownloadLoader = true;
      try {
        var responseObject = await this.$store.dispatch(
          "orderImage/getOrderImage",
          {
            orderNumber: this.orderDetails.orderNumber,
            billTo: this.orderDetails.billTo.id,
            jwtToken: this.$store.state.user.jwtToken,
            docType: image.docType,
            division: this.division
          }
        );

        var blob = this.convertByteArrayToPdf(
          responseObject.bytes,
          responseObject.contentType
        );
        this.downloadPdf(blob, responseObject.fileName);
      } catch (e) {
        this.$snotify.error(
          "There was an error retrieving image data.",
          "Error"
        );
      } finally {
        image.showDownloadLoader = false;
      }
    },
    async onDownloadAllImagesButtonClick() {
      this.showDownloadAllLoader = true;
      var imageTypes = [];

      this.$store.state.orderImage.orderImages.forEach(image => {
        if (image.isSelected) imageTypes.push(image.docType);
      });

      if (imageTypes.length == 0) {
        this.isLoadingImages = false;
        this.$snotify.error("Please select at least 1 image.", "Error");
        return;
      }
      try {
        var responseObject = await this.$store.dispatch(
          "orderImage/getSelectedOrderImages",
          {
            orderNumber: this.orderDetails.orderNumber,
            billTo: this.orderDetails.billTo.id,
            jwtToken: this.$store.state.user.jwtToken,
            docTypes: imageTypes,
            division: this.division
          }
        );
        var blob = this.convertByteArrayToPdf(
          responseObject.bytes,
          responseObject.contentType
        );
        this.downloadPdf(blob, responseObject.fileName);
      } catch (e) {
        this.$snotify.error(
          "There was an error retrieving image data.",
          "Error"
        );
      } finally {
        this.showDownloadAllLoader = false;
      }
    },
    downloadPdf(blob, fileName) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        window.URL = window.URL || window.webkitURL;
        var link = document.createElement("a");
        var url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        setTimeout(function() {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
      }
    },
    convertByteArrayToPdf(strByteArr, contentType) {
      var binaryString = window.atob(strByteArr);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);

      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      var blob = new Blob([bytes], { type: contentType });
      return blob;
    },
    onSelectAllImagesButtonClick() {
      this.$store.dispatch("orderImage/selectAllImages");
    }
  },
  computed: {
    ...mapGetters("orderImage", ["enableDownload"]), // {'enableDownload': 'orderImage/enableDownload'} ),  //   return this.$store.getters['orderImage/enableDownload']; // same
    ...mapState({
      //orderDetails: state => state.order.orderDetails,
      imageDetails: state => state.orderImage.orderImages
    }),
    showImageSection() {
      return (
        canViewImages(this.$store) && enableImaging[this.division.toUpperCase()]
      );
    },
    showOrderMapsSection() {
      return canViewOrderMaps(this.$store);
    },
    division() {
      return this.$route.params.division ? this.$route.params.division : this.division.toUpperCase();
    }
  }
};
</script>

<style scoped>
.col-print-1 {
  width: 33%;
  float: left;
}
.col-print-2 {
  width: 33%;
  float: left;
}
.col-print-3 {
  width: 33%;
  float: left;
}

#printVersion {
  display: none;
}
</style>
