<template id="tmplHereMap">
  <div id="divHereMap">
    <button
      id="btnHereMapData"
      class="btn btn-outline-info mb-2"
      size="sm"
      @click="populateMapFromData"
    >
      Reset Map
    </button>
    <b-alert id="altHereMapDelayNotification" show variant="light mb-1 text-dark p-0 text-justify">
      <h4 id="hdrHereMapFiveMin" class="alert-heading font-weight-bold">GPS pings are published in 5 minute intervals</h4>
      <p id="parHereMapConnectivity">Please note their availability is subject to network connectivity in the truck. Delayed GPS pings may indicate the tractor temporarily moved into a no service area.</p>
    </b-alert>
    <div id="divHereMapMap" class="here-map">
      <div id="divHereMapMapRef" ref="map" v-bind:style="{ width: width, height: height }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HereMap.vue",
  data() {
    return {
      map: {},
      platform: {},
      openBubble: null,
      ui: null,
      mapObjectsGroup: new H.map.Group(),
      officesGroup: new H.map.Group(),
      shippersGroup: new H.map.Group(),
      consigneesGroup: new H.map.Group(),
      trucksGroup: new H.map.Group(),
      routesGroup: new H.map.Group(),
      routePinsGroup: new H.map.Group()
    };
  },
  props: {
    apiKey: String,
    lat: String,
    lng: String,
    width: String,
    height: String,
    orderStatus: String,
    division: String,
    mapJson: {}
  },
  created() {
    this.platform = new H.service.Platform({
      apikey: this.apiKey
    });
  },
  mounted() {
    //Default map types
    let defaultLayers = this.platform.createDefaultLayers();

    //Instantiate map
    this.map = new H.Map(this.$refs.map, defaultLayers.vector.normal.map, {
      zoom: 15,
      center: {
        lat: this.lat,
        lng: this.lng
      },
      pixelRatio: window.devicePixelRatio || 1
    });

    //Resize listener to ensure map occupies whole map container.
    window.addEventListener("resize", () => this.map.getViewPort().resize());

    //Events
    let behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(this.map)
    );

    //Default UI
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers, "en-US");

    this.map.addObject(this.mapObjectsGroup);
    this.mapObjectsGroup.addObject(this.officesGroup);
    this.mapObjectsGroup.addObject(this.shippersGroup);
    this.mapObjectsGroup.addObject(this.consigneesGroup);
    this.mapObjectsGroup.addObject(this.trucksGroup);
    this.mapObjectsGroup.addObject(this.routesGroup);
    this.mapObjectsGroup.addObject(this.routePinsGroup);

    this.populateMapFromData();
  },
  methods: {
    populateMapFromData() {
      if (!this.mapJson) return; //

      let mapObjectData = this.mapJson;

      let shippers = mapObjectData.locations.filter(
        location => location.type == "Shipper"
      );
      let consignees = mapObjectData.locations.filter(
        location => location.type == "Consignee"
      );
      let trucks = mapObjectData.assets.filter(asset => asset.type == "Truck");
      let pinIcon = new H.map.Icon("/img/red-map-pin.png", {
        size: { w: 32, h: 32 }
      });
      let gasIcon = new H.map.Icon("/img/gas-pump-gray.png", {
        size: { w: 32, h: 32 }
      });

      let gasIconActive = new H.map.Icon("/img/gas-pump.png", {
        size: { w: 32, h: 32 }
      });

      let spgIcon = new H.map.Icon("/img/spg-consignee-gray.png", {
        size: { w: 32, h: 32 }
      });
      let spgIconActive = new H.map.Icon("/img/spg-consignee.png", {
        size: { w: 32, h: 32 }
      });

      let truckIcon = new H.map.Icon("/img/truck.png", {
        size: { w: 32, h: 32 }
      });
      let shipperIcon = new H.map.Icon("/img/building.png", {
        size: { w: 32, h: 32 }
      });

      shippers.forEach(shipper => {
        let marker = new H.map.Marker(
          {
            lat: shipper.coordinates.lat,
            lng: shipper.coordinates.lng
          },
          {
            icon: shipperIcon
          }
        );
        marker.setData({
          Id: shipper.id,
          Name: shipper.name,
          OrdersInProgress: shipper.ordersInProgress
        });
        marker.addEventListener("tap", this.showShipperInfo);
        this.shippersGroup.addObject(marker);
      });

      consignees.forEach(consignee => {
        let consigneeIcon = spgIcon;

        if (this.division && this.division.toUpperCase() == "FDG") {
          consigneeIcon = gasIcon;
        }

        if (this.orderStatus !== "CMP") {
          consigneeIcon = spgIconActive;

          if (this.division && this.division.toUpperCase() == "FDG") {
            consigneeIcon = gasIconActive;
          }
        }

        let marker = new H.map.Marker(
          {
            lat: consignee.coordinates.lat,
            lng: consignee.coordinates.lng
          },
          {
            icon: consigneeIcon
          }
        );
        marker.setData({
          Id: consignee.id,
          Name: consignee.name,
          Inventory: consignee.inventory,
          OrdersInProgress: consignee.ordersInProgress
        });
        marker.addEventListener("tap", this.showConsigneeInfo);
        this.consigneesGroup.addObject(marker);
      });

      if (this.orderStatus !== "CMP") {
        trucks.forEach(truck => {
          if (truck.checkCalls.length > 0) {
            let mostRecentCheckCall =
              truck.checkCalls[truck.checkCalls.length - 1]; //Assumes check calls are returned from the API sorted by date.
            let marker = new H.map.Marker(
              {
                lat: mostRecentCheckCall.lat,
                lng: mostRecentCheckCall.lng
              },
              {
                icon: truckIcon
              }
            );

            marker.setData({
              Id: truck.id,
              Destination: truck.destination,
              OrderInProgress: truck.orderInProgress,
              When: mostRecentCheckCall.when
            });
            marker.addEventListener("tap", this.showTruckInfo);
            this.trucksGroup.addObject(marker);
          }
        });
      }

      trucks.forEach(truck => {
        if (truck.checkCalls.length > 0) {
          truck.checkCalls.forEach(checkCall => {
            let marker = new H.map.Marker(
              {
                lat: checkCall.lat,
                lng: checkCall.lng
              },
              {
                icon: pinIcon
              }
            );
            marker.setData({
              Truck: truck.id,
              When: checkCall.when
            });
            marker.addEventListener("tap", this.showCheckCallInfo);
            this.routePinsGroup.addObject(marker);
          });
        }
      });

      this.adjustMapToViewAllObjects(this.map, this.mapObjectsGroup);
    },

    showInfo(evt) {
      if (this.openBubble !== null) {
        this.ui.removeBubble(this.openBubble);
        this.openBubble.dispose();
      }
      let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content: "<b>" + evt.target.getData().name + "</b>"
      });
      this.openBubble = bubble;
      this.ui.addBubble(this.openBubble);
    },

    showTruckInfo(evt) {
      if (this.openBubble !== null) {
        this.ui.removeBubble(this.openBubble);
        this.openBubble.dispose();
      }
      let data = evt.target.getData();

      let whenString = "";
      data.When.forEach(when => {
        whenString += "<span class='when'>" + when + "</span><br />";
      });

      let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content:
          '<div class="infoBubble"><b>Truck: ' +
          data.Id +
          "</b><br />" +
          '<span class="when"><b>Destination</b>' +
          data.Destination +
          "</span>" +
          '<br /><span class="when"><b>Order:</b>' +
          data.OrderInProgress +
          "</span><br />" +
          '<span class="when"><b>Location Reported At:</b><br />' +
          data.When.length +
          " GPS Ping(s)</span><br />" +
          whenString +
          "</div>"
      });

      this.openBubble = bubble;
      this.ui.addBubble(this.openBubble);
    },

    showCheckCallInfo(evt) {
      if (this.openBubble !== null) {
        this.ui.removeBubble(this.openBubble);
        this.openBubble.dispose();
      }
      let data = evt.target.getData();
      let whenString = "";
      data.When.forEach(when => {
        whenString += "<span class='when'>" + when + "</span><br />";
      });

      let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content:
          '<div class="infoBubble"><b>Truck: ' +
          data.Truck +
          "</b><br />" +
          '<span class="when bold">' +
          data.When.length +
          " GPS Ping(s)</span><br />" +
          whenString +
          "</div>"
      });

      this.openBubble = bubble;
      this.ui.addBubble(this.openBubble);
    },
    showConsigneeInfo(evt) {
      if (this.openBubble !== null) {
        this.ui.removeBubble(this.openBubble);
        this.openBubble.dispose();
      }
      let data = evt.target.getData();
      // let regularInventory = data.Inventory.filter(x => x.Commodity == "Regular").shift();
      // let premiumInventory = data.Inventory.filter(x => x.Commodity == "Premium").shift();
      // let dieselInventory = data.Inventory.filter(x => x.Commodity == "Diesel").shift();
      let nextDelivery = this.getConsigneeNextDelivery(data.OrdersInProgress);
      let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content:
          '<div class="infoBubble"><b>Delivery Site<br /><span class="when">' +
          "<b>Name:</b> " +
          data.Name +
          "<br /><b>Id: </b>" +
          data.Id +
          "</b>" +
          '<br /><br /><a href="/new-order-tender">Create Order</a></span>'
        //  '<a href="thekag.com">Enter Inventories</a><br /><a href="thekag.com">View Historical Orders</a><br /><br />'
        //'<b>Next Delivery:</b><br />Order <a href=thekag.com>' + nextDelivery.Id + '</a> - ' + nextDelivery.Status + '<br />' +
        //'ETA: ' + nextDelivery.NextStopETA + '<br /><br />'
        // 'Regular: ' + regularInventory.Quantity + ' ' + regularInventory.UnitOfMeasure + '<br />' +
        // 'Premium: ' + premiumInventory.Quantity + ' ' + premiumInventory.UnitOfMeasure + '<br />' +
        // 'Diesel: ' + dieselInventory.Quantity + ' ' + dieselInventory.UnitOfMeasure
      });
      bubble.addClass("infoBubble");
      this.openBubble = bubble;
      this.ui.addBubble(this.openBubble);
    },
    getConsigneeNextDelivery(orders) {
      return orders
        .filter(x => x.status == "Loaded")
        .sort((first, second) => {
          if (first.nextStopETA < second.nextStopETA) return -1;
          if (first.nextStopETA > second.nextStopETA) return 1;
          return 0;
        })
        .shift();
    },
    showShipperInfo(evt) {
      if (this.openBubble !== null) {
        this.ui.removeBubble(this.openBubble);
        this.openBubble.dispose();
      }
      let data = evt.target.getData();
      let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content:
          '<div class="infoBubble"><b>Shipper<br /><span class="when">' +
          "<b>Name:</b> " +
          data.Name +
          "<br /><b>Id: </b>" +
          data.Id +
          "</b>"
      });
      this.openBubble = bubble;
      this.ui.addBubble(this.openBubble);
    },

    adjustMapToViewAllObjects(map, group) {
      let minimumBoundingBox = group.getBoundingBox();
      const paddingFactor = 0.06;
      let paddedRectangle = new H.geo.Rect(
        minimumBoundingBox.getTop() + paddingFactor,
        minimumBoundingBox.getLeft() - paddingFactor,
        minimumBoundingBox.getBottom() - paddingFactor,
        minimumBoundingBox.getRight() + paddingFactor
      );
      map.getViewModel().setLookAtData({
        bounds: paddedRectangle
      });
    }
  }
};
</script>

<style scoped>
.infoBubble {
  width: 200px;
}

.bold {
  font-weight: bold;
}

span.when {
  font-size: 0.8em;
}
</style>
