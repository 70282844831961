<template id="tmplAssets">
  <!-- <b-card title="Final Delivery Assets" class="h-100"> -->
  <b-card id="crdAssets" class="h-100">
    <h4 id="hdrAssetsTitle">
      {{ title }}
    </h4>
    <table id="tblAssetType">
      <tr id="trAssetOrdType" v-for="(asset, index) in orderDetails.assets" v-bind:key="index">
        <td id="tdAssetType" class="pr-2 align-top">
          <b id="bAssetType">{{ asset.type }}:</b>
        </td>
        <td id="tdAssetTypeValue">{{ asset.value }}</td>
      </tr>
    </table>
    <table id="tblAssetDetails">
      <tr id="trAssetCompany">
        <td id="tdAssetCompany" class="pr-2 align-top">
          <b id="bAssetOpCompany">Operating Company: </b> {{ orderDetails.termCompany }}
        </td>
      </tr>
      <tr id="trAssetTermTruck">
        <td id="trAssetTermTruck">
          <b id="bAssetTermTruck">Truck Terminal:</b> {{ orderDetails.termTruck }}
        </td>
      </tr>
      <tr id="trAssetDispatchDesk">
        <td id="trAssetDisptchDesk">
          <b id="bAssetDispatchDesk">Dispatch Desk:</b> {{ orderDetails.desk }}
        </td>
      </tr>
    </table>
  </b-card>
</template>

<script>
  export default {
  name: "Assets.vue",
  props: ["orderDetails"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    title() {
      return "Final Delivery Assets";
    },
    isSpg() {
      return this.orderDetails.division.toUpperCase() === "SPG";
    }
  }
};
</script>

<style scoped></style>
