<template id="tmplReferenceNumbers">
  <b-card id="crdReferenceNumbers" title="Reference Numbers" class="h-100">
    <table id="tbleReferenceNumbers">
      <tr id="trReferenceNumbers" v-for="(item, index) in orderDetails.referenceNumbers" :key="index">
        <td id="tdReferenceNumLabel" class="pr-2">
          <b id="bRefNumLabel">{{ item.label }}:</b>
        </td>
        <td id="tdReferenceNumValue">{{ item.value }}</td>
      </tr>
    </table>
  </b-card>
</template>

<script>
  export default {
  Name: "Reference-Numbers.vue",
  props: ["orderDetails"],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped></style>
