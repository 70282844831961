<template id="tmplDetails">
  <b-card id="crdDetails" title="Details" class="h-100">
    <table id="tblDetails">
      <tr id="trDetailsOrdNum">
        <td id="tdDetailsOrdNumLbl" class="pr-2">
          <b id="bDetailsOrdNumLbl">Order Number:</b>
        </td>
        <td id="tdDetailsOrdNum">{{ orderDetails.orderNumber }}</td>
      </tr>

      <tr id="trDetailsOrdStatus">
        <td id="tdDetailsOrdStatusLbl" class="pr-2">
          <b id="bDetailsOrdStatusLbl">Status:</b>
        </td>
        <td id="tdDetailsOrdStatus">       
          <b-badge id="txtDetailsOrdStatus" class="badge-text" :variant="this.badgeColor(this.orderDetails.status)">{{ this.orderStatus(this.orderDetails.status) }}</b-badge>
        </td>
      </tr>
      <tr id="trDetailsOrdModBy">
        <td id="tdDetailsOrdModByLbl" class="pr-2">
          <b id="bDetailsOrdModByLbl">Contact:</b>
        </td>
        <td id="tdDetailsOrdModBy">{{this.orderDetails.modifiedBy === null ? this.orderDetails.createdBy : this.orderDetails.modifiedBy}}</td>
      </tr>
      <!-- <tr v-if="showRetainRunout">
        <td class="align-top pr-2">
          <b>Retain/Runout:</b>
        </td>
        <td class="align-top">
          {{ orderDetails.retainDateTimeLocal }} -
          <br />
          {{ orderDetails.runoutDateTimeLocal }}
        </td>
      </tr> -->

      <!-- <tr>
        <td class="align-top pr-2">
          <b>Remarks:</b>
        </td>
        <td class="align-top">{{ orderDetails.instructions }}</td>
      </tr> -->
    </table>
  </b-card>
</template>

<script>
  export default {
  name: "Details.vue",
  props: ["orderDetails"],
  data() {
    return {};
  },
  methods: {
    badgeColor(val) {
      switch (val) {
        case "AVL":
          return "primary";
        case "STD":
          return "warning";
        case "DSP":
          return "info";
        case "PLN":
          return "info";
        case "CMP":
          return "success";
        case "CAN":
          return "danger";
        case "PND":
          return "secondary";

        default:
          break;
      }
    },
    orderStatus(val) {
      switch (val) {
        case "AVL":
          return "Available";
        case "STD":
          return "Started";
        case "DSP":
          return "Dispatched";
        case "PLN":
          return "Planned";
        case "CMP":
          return "Completed";
        case "CAN":
          return "Cancelled";
        case "PND":
          return "Pending";

        default:
          break;
      }
    }
    
  },
  computed: {
    showRetainRunout() {
      return this.orderDetails.division.toUpperCase() === "FDG";
    }
  }
};
</script>

<style scoped></style>
