<template id="tmplStops">
  <b-card id="crdStops" title="Stops">
    <div id="divStops"
      class="mb-4"
      v-for="(stop, index) in orderDetails.stops"
      v-bind:key="stop.stopNumber"
    >
      <b-row id="rowStopType" class="mb-3">
        <b-col id="colStopType" md="4">
          <b id="bStopType">{{ stop.type }} at:</b>
          <br id="brAfterStopType" />

          <b-card-text id="crdTxtStopAddr">
            <b id="bStopCompName">{{ stop.company.name }}</b>
            <br id="brForCompAddr" />
            {{ stop.company.address }}, {{ stop.company.city }},
            {{ stop.company.state }} {{ stop.company.zip }}
          </b-card-text>
        </b-col>
        <b-col id="colStopsApptTimes" md="4">
          <b-card-text id="crdTxtStopsApptTimes">
            <span id="spnStopsApptTimes" v-if="showAppointmentTimes">
              <b id="bStopsApptLabel">Appointment:</b> {{ stop.earliestDateTimeLocal }} -
              {{ stop.latestDateTimeLocal }}

              <br id="brStopsAppointment" />
            </span>

            <b id="bStopsArrivalLbl">Arrival:</b>
            {{ stop.arrivalDateTimeLocal }}
            <i id="itlStopsArrivalDate"
              v-if="stop.status == 'Arrived' || stop.status == 'Departed'"
              class="fas fa-check-square done"
              v-b-tooltip.hover
              title="Completed / Done"
            ></i>
            <br id="brStopsArrivalDates" />
            <b id="bStopsDepartureLbl">Departure: </b>
            {{ stop.departureDateTimeLocal }}
            <i id="itlStopsDeparture"
              v-if="stop.status == 'Departed'"
              class="fas fa-check-square done"
              v-b-tooltip.hover
              title="Completed / Done"
            ></i>
          </b-card-text>
        </b-col>

        <b-col id="colStopsRefEarliestDate" md="4">
          <b-card-text id="crdTxtStopsEarliestDate">
            <b id="bStopsEarliestDatelabel">Earliest:</b>

            {{ stop.earliestDateTimeLocal | displayValidDate }}
            <br id="brStopsEarliestDate"/>
            <b id="bStopsLatestDate">Latest</b>

            {{ stop.latestDateTimeLocal | displayValidDate }}
          </b-card-text>
        </b-col>

        <b-col id="colStopsRefNum" md="4">
          <div id="divStopsRefNum" v-if="stop.referenceNumbers && stop.referenceNumbers.length > 0">
            <b id="bStopsRefNumHdr">Reference Numbers</b>
            <table id="tblStopsRefNums">
              <tr id="trStopsRefNums" v-for="(item, index) in stop.referenceNumbers" :key="index">
                <td id="tdStopsRefNumLabel" class="pr-2">
                  <b id="bStopsRefNumlabel">{{ item.label }}:</b>
                </td>
                <td id="tdStopsRefNumValue">{{ item.value }}</td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
      <!-- Freight Details Table -->
      <b-row id="rowStopsFreightDetails">
        <b-col id="colStopsFreightDetails">
          <b id="bFreightDetailsLabel">Freight Details</b>

          <b-table
            id="tblFreightDetails"
            :items="stop.freights"
            :responsive="true"
            :small="true"
            :fields="freightFields"
          >

            <template id="tmplStopsSupplier" v-slot:cell(supplier)="row">
              <div id="divStopsSupplier">{{ row.value.name }}</div>
            </template>
            <template id="tmplStopsPinCode" v-slot:cell(pinCode)="row" :visible="order.division!='SPG'">
              <div id="divStopsPinValue">{{ row.value ? row.value.pin : "N/A" }}</div>
            </template>
            <template id="tmplStopsAccountOf" v-slot:cell(accountOf)="row"  :visible="order.division!='SPG'">{{
              row.value ? row.value.name : "N/A"
            }}</template>
            <template id="tmplStopsCommodity" v-slot:cell(commodity)="row">{{
              row.value ? row.value.name : "N/A"
            }}</template>
            <template id="tmplNetQuan" v-slot:cell(quantityNet)="row">
               {{ buildQuantityStringNet(row) }}
            <!--   {{
              row.value.amount + "  " + row.item.quantityGross.amountUnitOfMeasure
            }}-->
            </template>
            <template id="tmplGrossQuan" v-slot:cell(quantityGross)="row">
               {{ buildQuantityStringGross(row) }}
              <!-- {{
              row.value.amount + "  " + row.value.unitOfMeasure
            }} -->
            </template>

            <template id="tmplStopsFreightRefNumber" v-slot:cell(referenceNumbers)="row">
              <ul id="ulStopsFrieghtRefNumbers" class="list-unstyled">
                <li id="liStopsFreightRefNumbers" v-for="r in row.item.referenceNumbers" v-bind:key="r.value">
                  <b id="bStopsFreightRefNumLabel">{{ r.label }}:</b> {{ r.value }}
                </li>
              </ul>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <hr id="hrEndLine" v-if="index != orderDetails.stops.length - 1" class="mb-5 mt-2" />
    </div>
  </b-card>
</template>

<script>
  export default {
  name: "Stops.vue",
  props: ["orderDetails"],
  data() {

    return {};
  },
  methods: {
      buildQuantityStringGross(row) {
          if (row.item.callForQuantity) {
            return "Call For Quantity";
          }
          return row.value.amount + " " + row.value.unitOfMeasure;
      },
      buildQuantityStringNet(row) {
          if (row.item.callForQuantity) {
            return "Call For Quantity";
          }
          return row.value.amount + " " + row.value.unitOfMeasure;
      }
    },
  computed: {
    freightFields() {
     // console.log('this.isSpg',this.isSpg);

      if (this.isSpg) {
        return [
          "commodity",
          "quantityNet",
          "quantityGross",
          "referenceNumbers"
        ];
      }
      return [
        "supplier",
        "pinCode",
        "accountOf",
        "commodity",
        "quantityNet",
        "quantityGross",
        "referenceNumbers"
      ];
    },
    showRetainRunout() {
      return this.orderDetails.division.toUpperCase() === "FDG";
    },
    showAppointmentTimes() {
      return false; // this.isSpg;
    },
    isSpg() {
      return this.orderDetails.division.toUpperCase() === "SPG";
    }
  },
  filters: {
    displayValidDate: (value) => {
        return (value || 'N/A')
    }
  }
};
</script>

<style scoped>
.done {
  color: green;
}
</style>
