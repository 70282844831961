<template id="tmplPrintButton">
  <b-button
    id="btnPrintButton"
    d-none
    d-md-block
    data-html2canvas-ignore="true"
    class="d-print-none float-right d-none d-lg-block"
    @click="printDetails()"
  >
    Print <span v-if="isLoading">.....</span>
  </b-button>
</template>

<script>

import html2canvas from "html2canvas";
import printJS from "print-js";

  export default {
  name: "Print-Button.vue",
  props: ["orderNumber"],
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    printDetails() {
      //var canvasElement = document.createElement("canvas");
      let orderNumber = this.orderNumber;

      this.isLoading = true;

      printJS({
        printable: "printVersion",
        type: "html",
        style: " #printVersion {display: block !important;}",
        targetStyles: ["*"],
        documentTitle: `TripFocus-Order-${orderNumber}.pdf`
      });

      this.isLoading = false;

      // html2canvas(this.$parent.$refs.content, {
      //   canvas: canvasElement,
      //   logging: false
      // }).then(canvas => {
      //   const imgData = canvas.toDataURL("image/jpg ");
      //   printJS({
      //     printable: imgData,
      //     type: "image",
      //     style: "h4 { color: red; }",
      //     documentTitle: `TripFocus-Order-${orderNumber}.pdf`
      //   });

      //this.isLoading = false;
      // setTimeout(function() {
      //   pdf.save(`TripFocus-Order-${orderNumber}.pdf`);
      //   this.isLoading = false;
      // }, 1000);

      // setTimeout(() => {
      //   pdf.save(`TripFocus-Order-${orderNumber}.pdf`);
      //   this.isLoading = false;
      // }, 1000);
      //});
    }
  }
};
</script>

<style scoped></style>
