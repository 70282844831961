/* eslint-disable */
import _ from "lodash";
import {
  utcDateToLocal,
  toClientReadableDate
} from "@/shared/utils/date-formatter";

export function convertCanonicalToSomethingNotStupid(orderCanonical) {
  let billTo = {
    id: "",
    name: "UNKNOWN"
  };

  let billToObject = _.find(orderCanonical.companies, function(o) {
    return o.type == "BillTo";
  });

  if (billToObject) {
    billTo = {
      id: billToObject.id,
      name: billToObject.name
    };
  }

  let stops = [];

  
  _.forEach(orderCanonical.stops, function(s, key) {
    let stop = {
      type: s.type.type == "PUP" ? "Pick-up" : "Drop-off",
      company: {
        id: s.company.id,
        name: s.company.name,
        type: s.company.type,
        address: s.company.location.address.line1,
        city: s.company.location.address.city,
        state: s.company.location.address.state,
        zip: s.company.location.address.postalCode
      },

      arrivalDateTimeLocal: toClientReadableDate(s.arrivalDateTimeLocal),
      departureDateTimeLocal: toClientReadableDate(s.departureDateTimeLocal),
      earliestDateTimeLocal: toClientReadableDate(s.earliestDateTimeLocal),
      latestDateTimeLocal: toClientReadableDate(s.latestDateTimeLocal),
      stopNumber: s.stopNumber,
      status: s.status.statusReason,
      freights: [],
      referenceNumbers: []
    };

    _.forEach(s.referenceData, function(r) {
      let referenceNumber = {
        type: r.type,
        value: r.value,
        label: r.label
      };

      stop.referenceNumbers.push(referenceNumber);
    });

    
    _.forEach(s.freights, function (f, key) {
      let gum = 'UNK';
      let num = 'UNK';

      var gross = _.find(f.quantities, function (o) {
        gum = o.unitOfMeasure;
        return o.qualifier == "Gross";
      });
//      console.log('gum', gum);
      var net = _.find(f.quantities, function (o) {
        num = o.unitOfMeasure;
        return o.qualifier == "Net";
      });
  //    console.log('num', num);

      let freight = {
        supplier: {
          id: f.supplier.id,
          name: f.supplier.name
        },
        accountOf: {
          id: f.accountOf.id,
          name: f.accountOf.name
        },
        commodity: {
          id: f.productId,
          name: f.name
        },
        quantityGross: {
          amount: gross.amount,
          unitOfMeasure: gum  
        },
        quantityNet: {
          amount: net.amount,
          unitOfMeasure: num
        },
        pinCode: {
          pin: f.pin
        },
        referenceNumbers: []
      };

      _.forEach(f.referenceData, function(r, key) {
        let referenceNumber = {
          type: r.type,
          value: r.value,
          label: r.label
        };

        freight.referenceNumbers.push(referenceNumber);
      });

      stop.freights.push(freight);
    });

    stops.push(stop);
  });

  console.log(stops);
  var orderLastDropStop = _.find(orderCanonical.stops, function(o) {
    return o.type.type == "DRP";
  });

  let referenceNumbers = [];

  _.forEach(orderCanonical.referenceData, function(r, key) {
    let referenceNumber = {
      type: r.type,
      value: r.value,
      label: r.label
    };

    referenceNumbers.push(referenceNumber);
  });

  let assets = [];

  _.forEach(orderCanonical.assets, function(a, key) {
    if (a.carrier) {
      _.forEach(a.carrier, function(c) {
        let asset = {
          type: a.type,
          value: c.name + " (" + a.id + ")"
        };

        assets.push(asset);
      });
    } else {
      let asset = (asset = {
        type: a.type,
        value: a.id
      });

      assets.push(asset);
    }
  });
  let order = {
    division: orderCanonical.division,
    orderNumber: orderCanonical.kagOrderNumber,
    status: orderCanonical.status.description,
    instructions: orderCanonical.instructions,
    customerOrderNumber: orderCanonical.customerOrderNumber,

    // shipmentStartDateTimeUtc: orderCanonical.shipmentStartDateTimeUtc,
    // shipmentEndDateTimeUtc: orderCanonical.shipmentEndDateTimeUtc,
    shipmentStartDateTimeLocal: toClientReadableDate(
      orderCanonical.shipmentStartDateTimeUtc
    ), //these 2 aren't utc from the Proc/Api
    shipmentEndDateTimeLocal: toClientReadableDate(
      orderCanonical.shipmentEndDateTimeUtc
    ),
    billTo: billTo,
    retainDateTimeUtc: orderLastDropStop ? orderLastDropStop.earliestDateTimeUtc : null,
    runoutDateTimeUtc: orderLastDropStop ? orderLastDropStop.latestDateTimeUtc : null,
    retainDateTimeLocal: utcDateToLocal(orderLastDropStop ? orderLastDropStop.earliestDateTimeUtc : null),
    runoutDateTimeLocal: utcDateToLocal(orderLastDropStop ? orderLastDropStop.latestDateTimeUtc : null),
    stops: stops,
    referenceNumbers: referenceNumbers,
    assets: assets,
    createdBy: orderCanonical.createdBy,
    modifiedBy: orderCanonical.modifiedBy
  };
  return order;
}
